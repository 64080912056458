import React from 'react';
import Functions from '../../Helpers/Functions'
import FetchData from '../../Helpers/FetchData';
import Calendario from '../../Helpers/Calendario'
import DownloadButton from '../../Helpers/Forms/DownloadButton';
import SinDatos from '../../Helpers/SinDatos';
import PaginationComponent from '../../Helpers/Forms/PaginationComponent.js';
import { ScrollPanel } from 'primereact/scrollpanel';
import './KilometrajePage.css';
import { Fieldset } from 'primereact/fieldset';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';

class KilometrajePage extends React.Component {
    constructor(props) {
        super(props)

        var hasta = new Date();
        var desde = new Date(hasta.getFullYear(), hasta.getMonth(), 1);
        var desdeHasta = [desde, hasta];

        this.state = {
            desdeHasta: desdeHasta,
            kilometraje: [],
            cargarKilometraje: true,
            panelCollapsed: true
        }

        this.fetchDataComponent = React.createRef();
    }

    ValidarFechas() {
        return true;
    };

    createUrl(pagina) {
        var desde = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[0];
        var hasta = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[1];

        const idUnidad = this.props.idUnidadSeleccionada;
        const FI = Functions.isEmpty(desde) ? "" : Functions.ToSQLDate(desde);
        const FF = Functions.isEmpty(hasta) ? "" : Functions.ToSQLDate(hasta);
        const finalUrl = process.env.REACT_APP_SERVICES_KILOMETRAJE
            + "IdUnidad=" + idUnidad
            + "&Page=" + pagina
            + "&FI=" + FI
            + "&FF=" + FF
            + "&HI=0"
            + "&HF=24"
        return finalUrl;
    }

    VerKilometraje_Click = (event) => {
        var validaciones = this.ValidarFechas();

        if (validaciones) {
            if (this.fetchDataComponent.current == null) {
                this.setState({ cargarNotificaciones: true });
            } else {
                const finalUrl = this.createUrl(0);
                this.fetchDataComponent.current.actualizarData(finalUrl);
            }
        }
    };

    onPageChange = (fetchComponent, newPage) => {
        var page = newPage - 1;
        const finalUrl = this.createUrl(page);
        fetchComponent.current.actualizarData(finalUrl);
    }

    render() {
        let kilometraje = null;

        if (this.state.cargarKilometraje) {
            const finalUrl = this.createUrl(0);
            var urlExportacionExcel = finalUrl + "&enExportacion=true";

            kilometraje = (
                <FetchData url={finalUrl} ref={this.fetchDataComponent}>
                    {data =>
                        <div>
                            <TablaRecorridos
                                unidad={this.props.unidadSeleccionada}
                                recorridos={data}
                                onPageChange={this.onPageChange}
                                sender={this.fetchDataComponent}>
                            </TablaRecorridos>
                        </div>
                    }
                </FetchData>
            );
        }

        let finalRender = (
            <div id="KilometrajePage">
                <div className="FiltrosRow">
                    <div className="CalendarioGroup">
                        <Calendario placeholder="Seleccione un rango de fechas" id="CalendarioKilometraje" desdeHasta={this.state.desdeHasta} onChange={(e) => this.setState({ desdeHasta: e.value })} />
                        <div className="input-group w-auto">
                            <div className="input-group-append">
                                <button className="btn btn-naranja" onClick={(e) => this.VerKilometraje_Click(e)}>Filtrar</button>
                            </div>
                        </div>
                    </div>
                    <div className="PanelOperaciones">
                        <DownloadButton url={urlExportacionExcel} />
                    </div>
                </div>
                {kilometraje}
            </div>
        );

        return finalRender;
    }
}

const TablaRecorridos = (props) => {
    const objRecorridos = props.recorridos;
    const tieneRecorridos = objRecorridos.TieneRecorrido;
    const recorridos = objRecorridos.Recorridos;
    const pageSize = objRecorridos.PageSize;
    const totalPages = objRecorridos.TotalPages;
    const currentPage = objRecorridos.CurrentPage;
    const totalRecords = pageSize * totalPages;
    var total = objRecorridos.TotalKm;
    var message = objRecorridos.Message;

    var mensaje = Functions.isEmpty(message) ? "La unidad no posee datos de kilometraje para el periodo solicitado." : message;
    let finalRender = <div className="SinPosiciones"><SinDatos mensaje={mensaje} /></div>;

    if (tieneRecorridos) {
        var renderRecorrido = recorridos.map(function (recorrido, index) {
            return <Recorrido key={index} unidad={props.unidad} clave={recorrido.Fecha} valor={recorrido.RecorridoKm} />
        });

        var renderSumatoria = (
            <Recorrido key="100" unidad={props.unidad} clave="Total" valor={total} negrita="true" />
        );

        finalRender = (
            <div>
                <div className="Kilometraje">
                    <ScrollPanel style={{ height: '100%' }} className="customScroll">
                        {renderRecorrido}
                        {renderSumatoria}
                    </ScrollPanel>
                </div>

                <div className="Paginador">
                    <PaginationComponent
                        currentPage={currentPage}
                        totalRecords={totalRecords}
                        onChange={e => props.onPageChange(props.sender, e)}>
                    </PaginationComponent>
                </div>
            </div>
        )
    }

    return finalRender;
};

const Recorrido = (props) => {
    var index = props.index;
    var clave = props.clave;
    var valor = props.valor + " km";
    var negrita = Functions.isEmpty(props.negrita) ? "" : "font-weight-bold";

    return (
        <div className="RecorridoKm" key={index}>
            <div className={negrita}>
                {clave}
            </div>
            <div className={negrita}>
                {valor}
            </div>
        </div>
    );
};

export default KilometrajePage;