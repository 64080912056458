import React from 'react';
import ProgressBar from '../../../Helpers/ProgressBar'
import TipoVehiculo from '../../../Helpers/TipoVehiculo'
import { ScrollPanel } from 'primereact/scrollpanel';
import './TableView.css';
import './ImageView.css';

export default class ListView extends React.Component {
  renderListView(MarcarUnidad, DesmarcarUnidad, MarcarUnidadClick) {
    let unidades = this.props.unidades == null ? [] : this.props.unidades;
    let finalRender;

    if (this.props.tipoView === 1) {
      finalRender = (
        <div className="ImageView">
          {unidades.map(function (unidad) {
            return <ImageView
              key={unidad.IdUnidad}
              unidad={unidad}
              MarcarUnidad={() => MarcarUnidad(unidad)}
              DesmarcarUnidad={() => DesmarcarUnidad(unidad)}
              MarcarUnidadClick={() => MarcarUnidadClick(unidad)} />
          })}
        </div>);
    }
    else if (this.props.tipoView === 2) {
      finalRender = (
        <div className="TableView">
          {unidades.map(function (unidad) {
            return <TableView
              key={unidad.IdUnidad}
              unidad={unidad}
              MarcarUnidad={() => MarcarUnidad(unidad)}
              DesmarcarUnidad={() => DesmarcarUnidad(unidad)}
              MarcarUnidadClick={() => MarcarUnidadClick(unidad)} />
          })}
        </div>);
    }

    return finalRender;
  };

  render() {
    let finalRender = (
      <div id="ListViewUnidades">
        <ScrollPanel style={{ height: '100%' }} className="customScroll">
          {this.renderListView(this.props.MarcarUnidad, this.props.DesmarcarUnidad, this.props.MarcarUnidadClick)}
        </ScrollPanel>
      </div>
    );

    return finalRender;
  }
}

const ImageView = (props) => {
  var unidad = props.unidad;
  var ultimaPosicion = unidad.UltimaPosicion;
  var render = null;

  // SOLO UNIDADES ACTIVAS
  if (unidad.EstadoAdmin !== 1)
    return render;

  // PUEDE NO TENER ÚLTIMA POSICIÓN (UNIDADES VIEJAS, UNIDADES NUEVAS (SIN POSICIÓN))
  var renderUbicacion = null;
  if (ultimaPosicion != null) {
    var classPorEstadoMotor = "VelocidadUnidad " + (ultimaPosicion.EstadoMotor === 1 ? "ColorMotorEncendido" : "ColorMotorApagado");
    var estadoMotor = ultimaPosicion.EstadoMotor === 0 ? "" : (ultimaPosicion.EstadoMotor === 1 ? "con motor encendido" : "con motor apagado")
    var texto = ultimaPosicion.Velocidad + " km/h " + estadoMotor;

    renderUbicacion = (
      <React.Fragment>
        <div className={classPorEstadoMotor}>{texto}</div>
      </React.Fragment>
    );
  }

  render = (
    <div className="ImageViewUnidad" onMouseEnter={props.MarcarUnidad} onMouseLeave={props.DesmarcarUnidad} onClick={props.MarcarUnidadClick} >
      <div className="UnidadContenido">
        <div>
          <div className="TipoUnidad">
            <TipoVehiculo tipoVehiculo={unidad.TipoVehiculo} />
          </div>
          <div className="InfoUnidad">
            <span className="NombreUnidad">{unidad.Descripcion}</span>
            <span className="IdUnidad">{unidad.StrUnidad}</span>
          </div>
        </div>
        {renderUbicacion}
      </div>
    </div>
  );

  return render;
};

const TableView = (props) => {
  var unidad = props.unidad;
  var ultimaPosicion = unidad.UltimaPosicion;
  var render = null;

  // SOLO UNIDADES ACTIVAS
  if (unidad.EstadoAdmin !== 1)
    return render;

  // PUEDE NO TENER ÚLTIMA POSICIÓN (UNIDADES VIEJAS, UNIDADES NUEVAS (SIN POSICIÓN))
  var textoVelocidad = null;
  var ubicacion = null;
  var classPorEstadoMotor = 'TipoUnidad rounded-circle border border-gris-oscuro';
  if (ultimaPosicion != null) {
    var arrayBordeEstadoMotor = { 0: 'border-gris-claro', 1: 'border-verde', 2: 'border-rojo' };
    var arrayEstadoMotor = { 0: 'Motor sin estado', 1: 'Motor encendido', 2: 'Motor apagado' };

    classPorEstadoMotor = "TipoUnidad rounded-circle border " + arrayBordeEstadoMotor[ultimaPosicion.EstadoMotor];
    textoVelocidad = ultimaPosicion.Velocidad + " km/h. " + arrayEstadoMotor[ultimaPosicion.EstadoMotor];
    ubicacion = ultimaPosicion.Ubicacion;
  }

  render = (
    <div className="TableViewUnidad" onMouseEnter={props.MarcarUnidad} onMouseLeave={props.DesmarcarUnidad} onClick={props.MarcarUnidadClick}>
      <div className="UnidadContenido">
        <div className={classPorEstadoMotor}>
          <TipoVehiculo tipoVehiculo={unidad.TipoVehiculo} />
        </div>
        <div className="InfoUnidad">
          <div className="NombreUnidad">{unidad.Descripcion}</div>
          {/*<div className="IdUnidad">{unidad.StrUnidad}</div>*/}
          <div className="Ubicacion">{ubicacion}</div>
          <div className="VelocidadUnidad">{textoVelocidad}<span className="IdUnidad">{unidad.StrUnidad}</span></div>
        </div>
      </div>
    </div>
  );

  return render;
};