import React from 'react';
import IFrame from '../../Helpers/IFrame';
import { Grid, Segment, Image, Container, Form, Input, Divider, TextArea, Dropdown } from 'semantic-ui-react';
import './CaracteristicasPage.css';
import ExplicacionFormulario from '../../Helpers/Forms/ExplicacionFormulario';
import TextboxComponent from '../../Helpers/Forms/TextboxComponent';
import CheckboxComponent from '../../Helpers/Forms/CheckboxComponent';
import DropdownComponent from '../../Helpers/Forms/DropdownComponent';

class CaracteristicasPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            informacionUnidad: null
        }
    }

    getData() {
        var finalUrl = process.env.REACT_APP_SERVICES_INFOUNIDAD + "IdUnidad=" + this.props.unidadSeleccionada.IdUnidad;

        fetch(finalUrl, {
            credentials: 'include',
            cache: 'no-store'
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("not ok");
                }
                return response.json();
            })
            .then((result) => {
                this.setState({ informacionUnidad: result });
            })
            .catch((err) => {
                console.info("error fetch. " + err);
            });
    }

    componentDidMount() {
        this.getData();
    };

    GuardarInfoUnidad = async () => {
        var finalUrl = process.env.REACT_APP_SERVICES_INFOUNIDADGUARDAR;

        // CONEXIÓN
        const response = await fetch(finalUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(this.state.informacionUnidad),
            credentials: 'include',
            cache: 'no-store'
        });

        if (!response.ok)
            alert('Error guardando la información de la unidad')
        const result = await response.text();

        if (result === "OK") {
            alert('Actualización exitosa');
            this.props.CerrarEditarUnidad();
        } else {
            alert('Error procesando. ' + result);
        }
    }

    render() {
        if (this.state.informacionUnidad == null)
            return null;

        var finalRender = (
            <div id="CaracteristicasPage">
                <ExplicacionFormulario valor="A continuación se muestran todos los datos de la cuenta. Realice los cambios que requiera y presione el botón “guardar cambios”. Si no desea almacenar cambios, presione el botón “Cancelar”." />
                <Divider />

                <div className="Formulario">
                    <Form>
                        <Grid divided padded>
                            <Grid.Column width={11}>
                                <Grid columns='equal' celled='internally'>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            {this.renderTablaPrincipalLadoIzquierdo()}
                                        </Grid.Column>

                                        <Grid.Column width={8}>
                                            {this.renderTablaPrincipalLadoDerecho()}
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Subtitulo valor="Administración" />

                                            <TablaAdministracion data={this.state} {...this.props} />
                                        </Grid.Column>

                                        <Grid.Column>
                                            <Subtitulo valor="Notificaciones" />

                                            <TablaNotificaciones data={this.state} {...this.props} />
                                        </Grid.Column>

                                        <Grid.Column>
                                            <Subtitulo valor="Comportamiento" />

                                            <TablaComportamiento data={this.state} {...this.props} />
                                        </Grid.Column>
                                    </Grid.Row>

                                </Grid>
                            </Grid.Column>

                            {/* LADO DERECHO */}
                            <Grid.Column width={5}>
                                <Subtitulo valor="Geocerca de seguridad (apagado de motor)" />
                                <ExplicacionFormulario valor="Cuando la plataforma detecte que la unidad se encuentra “fuera” de esta geocerca, enviará un comando de “apagado de motor”. Si no requiere la funcionalidad para esta unidad, seleccione “ninguna”" />

                                <p>&nbsp;</p>
                                <div className="ClaveValor">
                                    <span className="Label">
                                        <label>Geocerca</label>
                                    </span>
                                    <span className="Control">
                                        <DropdownComponent
                                            metadata={this.state.informacionUnidad.GeocercasDisponibles}
                                            valor={this.state.informacionUnidad.IdGeocercaSeguridad}
                                            onChange={(e, { value }) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, IdGeocercaSeguridad: value } })}>
                                        </DropdownComponent>
                                    </span>
                                </div>
                                {/* <p>&nbsp;</p>
                            <Subtitulo valor="Notas distribuidor" />
                            <TextArea
                                valor={this.state.informacionUnidad.NotasDistribuidor}
                                onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, NotasDistribuidor: e.target.value } })}>
                            </TextArea>

                            <p>&nbsp;</p>
                            <Subtitulo valor="Notas administración" />
                            <TextArea
                                valor={this.state.informacionUnidad.NotasAdministrador}
                                onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, NotasAdministrador: e.target.value } })}>
                            </TextArea> */}

                                <p>&nbsp;</p>
                                <button className="btn btn-naranja btn-block" onClick={this.GuardarInfoUnidad}>Guardar cambios sobre la unidad</button>
                                <button className="btn btn-rojo btn-block" onClick={this.props.CerrarEditarUnidad}>Cancelar opción</button>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </div>
            </div >
        );

        return finalRender;
    }

    renderTablaPrincipalLadoIzquierdo() {
        var numeroId = this.state.informacionUnidad.StrUnidad;
        var idCuenta = this.state.informacionUnidad.IdCuenta;
        var descripcion = this.state.informacionUnidad.Descripcion;
        var marca = this.state.informacionUnidad.Marca;
        var placa = this.state.informacionUnidad.Placa;
        var esn = this.state.informacionUnidad.Esn;
        var sim = this.state.informacionUnidad.NroSim;
        var esAdmin = this.props.usuarioActual.Tipo !== 1;

        var finalRender = (
            <div>
                <ClaveValor label="Número id" valor={numeroId} readOnly={true} />
                <ClaveValorDropdown label="Cuenta" metadata={this.state.informacionUnidad.CuentasDisponibles} valor={idCuenta} readOnly={esAdmin} />
                <ClaveValor label="Descripción"
                    valor={descripcion}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Descripcion: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Marca"
                    valor={marca}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Marca: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Placa"
                    valor={placa}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Placa: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Esn unidad"
                    valor={esn}
                    readOnly={esAdmin}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Esn: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Número SIM Card" valor={sim} readOnly={esAdmin} />
            </div>
        );

        return finalRender;
    }


    renderTablaPrincipalLadoDerecho() {
        var imei = this.state.informacionUnidad.Imei;
        var modelo = this.state.informacionUnidad.Modelo;
        var color = this.state.informacionUnidad.Color;
        var serial = this.state.informacionUnidad.Serial;
        var pool = this.state.informacionUnidad.Pool;
        var nroContenedor = this.state.informacionUnidad.NroContenedor;
        var esAdmin = this.props.usuarioActual.Tipo !== 1;

        var finalRender = (
            <div>
                <ClaveValor label="IMEI" valor={imei} readOnly={esAdmin} />
                <ClaveValor label="Modelo"
                    valor={modelo}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Modelo: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Color"
                    valor={color}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Color: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Nro. serial vehículo" valor={serial} readOnly={esAdmin} />
                <ClaveValor label="Pool SIM" valor={pool} readOnly={esAdmin} />
                <ClaveValor label="#Contenedor" valor={nroContenedor} readOnly={esAdmin} />
            </div>
        );

        return finalRender;
    }
}




const TablaAdministracion = (props) => {
    var fechaVenta = props.data.informacionUnidad.FechaVenta;
    var fechaInstalacion = props.data.informacionUnidad.FechaInstalacion;
    var idPaquete = props.data.informacionUnidad.IdPaquete;
    var idEstado = props.data.informacionUnidad.IdEstado;
    var esAdmin = props.usuarioActual.Tipo !== 1;

    fechaVenta = new Date(fechaVenta);
    fechaVenta = fechaVenta.getDate() + "/" + (fechaVenta.getMonth() + 1) + "/" + fechaVenta.getFullYear();
    fechaInstalacion = new Date(fechaInstalacion);
    fechaInstalacion = fechaInstalacion.getDate() + "/" + (fechaInstalacion.getMonth() + 1) + "/" + fechaInstalacion.getFullYear();

    var finalRender = (
        <div>
            <ClaveValor label="Fecha venta" valor={fechaVenta} readOnly={esAdmin} />
            <ClaveValor label="Fecha instalación" valor={fechaInstalacion} readOnly={esAdmin} />
            <ClaveValorDropdown label="Paquete" metadata={props.data.informacionUnidad.PaquetesDisponibles} valor={idPaquete} readOnly={esAdmin} />
            <ClaveValorDropdown label="Estado unidad" metadata={props.data.informacionUnidad.EstadosDisponibles} valor={idEstado} readOnly={esAdmin} />
        </div>
    );

    return finalRender;
}

const TablaNotificaciones = (props) => {
    var botonPanico = props.data.informacionUnidad.Notificaciones.BotonPanico;
    var limiteVelocidadViolado = props.data.informacionUnidad.Notificaciones.LimiteVelocidadViolado;
    var energiaPrincipalCortada = props.data.informacionUnidad.Notificaciones.EnergiaPrincipalCortada;
    var ignicionEncendida = props.data.informacionUnidad.Notificaciones.IgnicionEncendida;
    var ignicionApagada = props.data.informacionUnidad.Notificaciones.IgnicionApagada;
    var vehiculoRemolcado = props.data.informacionUnidad.Notificaciones.VehiculoRemolcado;
    var puertaAbierta = props.data.informacionUnidad.Notificaciones.PuertaAbierta;
    var enganche = props.data.informacionUnidad.Notificaciones.Enganche;
    var desenganche = props.data.informacionUnidad.Notificaciones.Desenganche;
    var esAdmin = props.usuarioActual.Tipo === 1;

    var finalRender = (
        <div className="CheckBoxContainer">
            <CheckboxComponent label="Botón de pánico" checked={botonPanico} readOnly={esAdmin} />
            <CheckboxComponent label="Límite de velocidad violado" checked={limiteVelocidadViolado} readOnly={esAdmin} />
            <CheckboxComponent label="Energía principal" checked={energiaPrincipalCortada} readOnly={esAdmin} />
            <CheckboxComponent label="Ignición apagada" checked={ignicionEncendida} readOnly={esAdmin} />
            <CheckboxComponent label="Vehículo remolcado" checked={vehiculoRemolcado} readOnly={esAdmin} />
            <CheckboxComponent label="Puerta abierta" checked={puertaAbierta} readOnly={esAdmin} />
            <CheckboxComponent label="Enganche" checked={enganche} readOnly={esAdmin} />
            <CheckboxComponent label="Desenganche" checked={desenganche} readOnly={esAdmin} />
        </div>
    );

    return finalRender;
}

const TablaComportamiento = (props) => {
    var AVLNormal = props.data.informacionUnidad.Comportamiento.AVLNormal;
    var geocercaSeguridad = props.data.informacionUnidad.Comportamiento.GeocercaSeguridad;
    var apagadoPorBotonPanico = props.data.informacionUnidad.Comportamiento.ApagadoPorBotonPanico;

    var finalRender = (
        <div className="CheckBoxContainer">
            <CheckboxComponent label="Geocerca de seguridad" checked={AVLNormal} />
            <CheckboxComponent label="AVL normal" checked={geocercaSeguridad} />
            <CheckboxComponent label="Apagado por botón de pánico" checked={apagadoPorBotonPanico} />
        </div>
    );

    return finalRender;
}

const Subtitulo = (props) => {
    var finalRender = (
        <div className="Subtitulo">{props.valor.toUpperCase()}</div>
    );

    return finalRender;
}


const ClaveValor = (props) => {
    var id = props.label;
    var label = props.label + ":";
    var readOnly = props.readOnly === undefined ? false : props.readOnly;

    return (
        <div className="ClaveValor">
            <span className="Label">
                <label htmlFor={id} >{label}</label>
            </span>
            <span className="Control">
                <Input id={id} value={props.valor} disabled={readOnly} maxlength="250" onChange={props.onChange} />
            </span>
        </div>
    );
};

const ClaveValorDropdown = (props) => {
    var id = props.label;
    var label = props.label + ":";
    var stateOptions = props.metadata.map(m => ({
        key: m.ID,
        text: m.Valor,
        value: m.ID
    }));

    var text = "";
    for (var index = 0; index < props.metadata.length; ++index) {
        var m = props.metadata[index];
        if (m.ID == props.valor) {
            text = m.Valor;
        }
    }

    return (
        <div className="ClaveValor">
            <span className="Label">
                <label htmlFor={id}>{label}</label>
            </span>
            <span className="Control">
                {/* <Dropdown options={stateOptions} text={text} /> */}
                <Input id={id} value={text} disabled={true} maxlength="250" />
            </span>
        </div>
    );
};

export default CaracteristicasPage;