import React from 'react';
import TodasMisUnidadesPage from './Unidades/TodasMisUnidades/TodasMisUnidadesPage'
import ResumenPage from './Unidades/Resumen/ResumenPage'
import RecorridoPage from './Unidades/Recorrido/RecorridoPage'
import FuncionesEnviadasPage from './Unidades/FuncionesEnviadas/FuncionesEnviadasPage'
import NotificacionesPage from './Unidades/Notificaciones/NotificacionesPage'
import KilometrajePage from './Unidades/Kilometraje/KilometrajePage'
import EnviarFuncionPage from './Unidades/EnviarFuncion/EnviarFuncionPage'
import GeocercasPage from './Unidades/Geocercas/GeocercasPage'
import CombustiblePage from './Unidades/Combustible/CombustiblePage'
import CaracteristicasPage from './Unidades/Caracteristicas/CaracteristicasPage'
import { Route, Switch, Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { Sidebar } from 'primereact/sidebar';
import Nav from 'react-bootstrap/Nav'
import queryString from 'query-string';
import Functions from './Helpers/Functions';
import OverlayText from './Helpers/OverlayText';
import Button from 'react-bootstrap/Button';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import ModalComponent from './Helpers/Modals/ModalComponent';
import MinusSvg from '../../assets/images/Minus.svg';
import PlusSvg from '../../assets/images/Plus.svg';
import SvgComponent from './Helpers/SvgComponent';


class ContentController extends React.Component {
    constructor(props) {
        super(props);

        // SE ENVIA LA PÁGINA DESDE LA URL
        var paginaInicial = "/";
        var paginasValidas = ["Resumen", "Recorrido", "FuncionesEnviadas", "Notificaciones", "Kilometraje", "EnviarFuncion", "Geocercas"];
        var paginaPorUrl = this.props.location.pathname.replace("/", "");
        var paginaValida = Functions.isInArray(paginaPorUrl, paginasValidas);
        if (paginaValida) {
            paginaInicial = paginaPorUrl;
        }

        // SE ENVIA LA UNIDAD COMO QUERYSTRING
        var params = queryString.parse(this.props.location.search);
        var idUnidadInicial = null;
        var idUnidadPorQueryString = params.IdUnidad
        if (idUnidadPorQueryString != null) {
            idUnidadInicial = idUnidadPorQueryString;
        }

        this.state = {
            paginaSeleccionada: paginaInicial,
            unidadSeleccionada: null,
            idUnidadSeleccionada: idUnidadInicial,
            showSidebar: true
        };

        this.OnUnidadSeleccionada = this.OnUnidadSeleccionada.bind(this);
        this.IrPaginaPrincipal = this.IrPaginaPrincipal.bind(this);
        this.PestañaChange = this.PestañaChange.bind(this);

        this.MarcarUnidadClick = this.MarcarUnidadClick.bind(this);

        this.closeIcon = React.createRef();
        this.sidebarComponent = React.createRef();
        this.sidebarComplete = React.createRef();
    }

    OnUnidadSeleccionada = (unidad) => {
        this.setState({ unidadSeleccionada: unidad, idUnidadSeleccionada: unidad.IdUnidad })
    };

    IrPaginaPrincipal = () => {
        this.props.VerMapaTodasMisUnidades();

        this.setState({ paginaSeleccionada: "/", unidadSeleccionada: null, idUnidadSeleccionada: null })
        this.props.history.push({ pathname: '/' });
    };

    PestañaChange = (tabSeleccionado) => {
        tabSeleccionado = tabSeleccionado.replace("/", "");

        // EN TODOS LOS CASOS SE CARGA EL MAPA DE ÚLTIMA POSICIÓN, DE OTRO MODO MAPA DE RECORRIDO
        if (tabSeleccionado !== "Recorrido") {
            this.props.VerMapaUltimaPosicion();
        } else {
            this.props.VerMapaRecorrido();
        }

        this.setState({ paginaSeleccionada: "/" + tabSeleccionado })
    };

    // EVENTOS MAPA DE TODAS MIS UNIDADES
    MarcarUnidadClick = (unidad) => {
        this.props.VerMapaUltimaPosicion();

        this.setState({ paginaSeleccionada: "/Resumen", unidadSeleccionada: unidad, idUnidadSeleccionada: unidad.IdUnidad })
        this.props.history.push({ pathname: '/Resumen', search: '?IdUnidad=' + unidad.IdUnidad });
    };


    // IR A PESTAÑA
    GotoToTab = (tab) => {
        var pathname = '/' + tab;
        var search = '?IdUnidad=' + this.state.unidadSeleccionada.IdUnidad;
        this.props.history.push({ pathname, search });
    };


    renderPage() {
        // RENDER PÁGINA PRINCIPAL
        var renderPaginaPrincipal = (
            <TodasMisUnidadesPage
                {...this.props}
                idUnidadSeleccionada={this.state.idUnidadSeleccionada}
                MarcarUnidadClick={this.MarcarUnidadClick}>
            </TodasMisUnidadesPage>
        );

        // RENDER PÁGINA INTERNA
        var renderPaginaInterna = (
            <InternalPages
                {...this.props}

                OnUnidadSeleccionada={this.OnUnidadSeleccionada}
                PestañaChange={this.PestañaChange}
                PaginaPrincipalClick={this.IrPaginaPrincipal}
                GotoToTab={this.GotoToTab}

                unidadSeleccionada={this.state.unidadSeleccionada}
                idUnidadSeleccionada={this.state.idUnidadSeleccionada}
                actualizarInformacionUnidad={this.props.actualizarInformacionUnidad} />
        );

        let finalRender;
        if (this.state.paginaSeleccionada === "/") {
            finalRender = renderPaginaPrincipal;
        } else {
            if (this.state.idUnidadSeleccionada == null) {
                finalRender = renderPaginaPrincipal;
            } else {
                finalRender = renderPaginaInterna;
            }
        }
        return finalRender;
    };

    sidebar_Click(e) {
        e.currentTarget.blur();
        var currHeight = Math.round($(this.sidebarComponent).height());
        var minimizeHeigth = (-(currHeight - 60)).toString() + "px";
        $(this.sidebarComponent).animate({ bottom: (this.state.showSidebar) ? minimizeHeigth : '0' }, 350);
        //$(this.closeIcon).animate({ bottom: (this.state.showSidebar) ? '10' : '350' }, 350);

        this.setState({ showSidebar: !this.state.showSidebar })

        // if (this.sidebarComponent != null) {
        //     var display = this.sidebarComponent.container.style.display;
        //     if (display === "" || display === "block") {
        //         this.sidebarComponent.container.style.display = "none";
        //         this.closeIcon.style.left = "0px";
        //         this.closeIcon.childNodes[0].childNodes[0].className = "p-sidebar-close-icon pi pi-plus fa-lg";
        //     } else {
        //         this.sidebarComponent.container.style.display = "block";
        //         this.closeIcon.style.left = "450px";
        //         this.closeIcon.childNodes[0].childNodes[0].className = "p-sidebar-close-icon pi pi-times fa-lg";
        //     }
        // }
    }

    renderIcono() {
        var closeClass = (this.state.showSidebar ? MinusSvg : PlusSvg);

        return (
            <Button className="close CloseButton" variant="close" onClick={(e) => this.sidebar_Click(e)}>
                <span className="fa-lg text-light">
                    <SvgComponent icono={closeClass} />
                </span>
            </Button>
            // <button className="" ref={el => this.closeIcon = el}>

            // </button>
            // {/* <button class="p-sidebar-close p-link" onClick={(e) => this.sidebar_Click()}>
            //     <span class="p-sidebar-close-icon pi pi-times fa-lg"></span>
            // </button> */}
        )
    }

    render() {
        // const sidebarStyle = { display: this.state.showSidebar ? 'block' : 'none' };
        // const closeStyle = { left: this.state.showSidebar ? '398px' : '-2px' };

        let finalRender = (
            <React.Fragment>
                <div ref={el => this.sidebarComplete = el}>
                    <div className="Sidebar" ref={el => this.sidebarComponent = el}>
                        <div className="CloseIcon" ref={el => this.closeIcon = el}>
                            {this.renderIcono()}
                        </div>
                        {this.renderPage()}
                    </div>
                    {/* <div className="CloseIcon" style={closeStyle}> */}
                </div>
            </React.Fragment>
        );

        return finalRender;
    }
}

class InternalPages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            unidadSeleccionada: props.unidadSeleccionada,
            actualizarInformacionUnidad: false
        };
    }

    componentDidMount() {
        // SE INGRESA DESDE LA URL A UNA PÁGINA INTERNA (QUIZÁS POR F5, COPY-PASTE DE LA URL)
        // OBTENER LA INFORMACIÓN DE LA UNIDAD
        if (this.state.unidadSeleccionada == null) {
            this.getData();
        }
    };

    getData() {
        var self = this;
        const finalUrl = process.env.REACT_APP_SERVICES_RESUMEN + "IdUnidad=" + this.props.idUnidadSeleccionada;

        fetch(finalUrl, {
            credentials: 'include',
            cache: 'no-store'
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                self.props.OnUnidadSeleccionada(result);
                self.setState({ unidadSeleccionada: result });
            })
            .catch((err) => {
            });
    }

    ActualizarInformacionUnidad() {
        this.getData();
    }

    renderCommonHeader() {
        var descripcion = this.state.unidadSeleccionada.Descripcion;
        var idUnidad = this.state.unidadSeleccionada.StrUnidad;
        var titulo = descripcion + " - " + idUnidad;

        var finalRender = (
            <div id="commonHeader">
                <div className="commonHeaderOuter bg-azul">
                    <button className="btn btn-primary fa fa-arrow-left fa-lg" onClick={this.props.PaginaPrincipalClick} />
                    <span id="commonHeaderTitle">
                        <OverlayText texto={titulo} texto1={descripcion} texto2={idUnidad} id="tooltipUnidad" placement="right" />
                    </span>
                </div>
            </div>
        );

        return finalRender;
    };

    renderPagina() {
        var idUnidad = this.props.idUnidadSeleccionada;

        var finalRender = (
            <Switch>
                <Route path="/Resumen">
                    <ResumenPage idUnidadSeleccionada={idUnidad} {...this.props} />
                </Route>
                <Route path="/Recorrido">
                    <RecorridoPage idUnidadSeleccionada={idUnidad} {...this.props} />
                </Route>
                <Route path="/FuncionesEnviadas">
                    <FuncionesEnviadasPage idUnidadSeleccionada={idUnidad} {...this.props} />
                </Route>
                <Route path="/Notificaciones">
                    <NotificacionesPage idUnidadSeleccionada={idUnidad} {...this.props} />
                </Route>
                <Route path="/EnviarFuncion">
                    <EnviarFuncionPage idUnidadSeleccionada={idUnidad} {...this.props} />
                </Route>
                <Route path="/Kilometraje">
                    <KilometrajePage idUnidadSeleccionada={idUnidad} {...this.props} />
                </Route>
                <Route path="/Geocercas">
                    <GeocercasPage idUnidadSeleccionada={idUnidad} {...this.props} />
                </Route>
                {/* <Route path="/Combustible">
                    <CombustiblePage idUnidadSeleccionada={idUnidad} {...this.props} />
                </Route> */}
                <Route path="/Caracteristicas">
                    <CaracteristicasPage idUnidadSeleccionada={idUnidad} {...this.props} />
                </Route>
            </Switch>
        );

        return finalRender;
    }

    render() {
        let render = null;
        if (this.state.unidadSeleccionada != null) {
            render = (
                <div id="InternalPage">
                    {this.renderCommonHeader()}
                    <MainTabs {...this.props} />
                    <ReportesTab {...this.props} />
                    <OperacionesTab {...this.props} onActualizarInformacionUnidad={() => this.ActualizarInformacionUnidad()} />
                    {this.renderPagina()}
                </div>
            );
        }

        return render;
    };
};

class MainTabs extends React.Component {
    render() {
        const currentTab = this.props.location.pathname.replace("/", "");
        const search = this.props.location.search;

        // ACTIVACIONES
        var tabReportesActivo = Functions.isInArray(currentTab, ["Recorrido", "FuncionesEnviadas", "Notificaciones", "Kilometraje"]);
        var tabOperacionesActivo = Functions.isInArray(currentTab, ["EnviarFuncion", "Geocercas"]);

        let tabs = null;
        if (currentTab !== "") {
            tabs = (
                <div className="mainTabs bg-gris-claro">
                    <Nav variant="pills" className="nav-fill" activeKey={currentTab} onSelect={this.props.PestañaChange}>
                        <Nav.Item>
                            <Nav.Link eventKey="Resumen" as={Link} to={{ pathname: '/Resumen', search: search }}>Resumen</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={tabReportesActivo} eventKey="/Recorrido" as={Link} to={{ pathname: 'Recorrido', search: search }}>Reportes</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={tabOperacionesActivo} eventKey="/EnviarFuncion" as={Link} to={{ pathname: 'EnviarFuncion', search: search }}>Acciones</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            );
        }

        return tabs;
    }
};

class ReportesTab extends React.Component {
    render() {
        const currentTab = this.props.location.pathname.replace("/", "");
        const search = this.props.location.search;

        // VERIFICAR QUE SI SE PUEDA PINTAR
        var tabsActivos = Functions.isInArray(currentTab, ["Recorrido", "FuncionesEnviadas", "Notificaciones", "Kilometraje"]);

        // PERMISOS
        var tipoUsuario = this.props.usuarioActual.Tipo;
        var noEsUsuarioTemporal = tipoUsuario >= 1 && tipoUsuario <= 3;
        var funcionesHabilitadas = this.props.usuarioActual.FuncionesHabilitadas;
        var tienePermisosSobreRecorrido = noEsUsuarioTemporal || Functions.isInArray(4, funcionesHabilitadas);
        var tienePermisosSobreFuncionesEnviadas = noEsUsuarioTemporal || Functions.isInArray(5, funcionesHabilitadas);
        var tienePermisosSobreNotificaciones = noEsUsuarioTemporal || Functions.isInArray(6, funcionesHabilitadas);
        var tienePermisosSobreKilometraje = noEsUsuarioTemporal || Functions.isInArray(7, funcionesHabilitadas);

        var renderTabRecorrido = null;
        if (tienePermisosSobreRecorrido)
            renderTabRecorrido = (<Nav.Link eventKey="Recorrido" as={Link} to={{ pathname: "Recorrido", search: search }}>Recorrido</Nav.Link>)

        var renderTabFuncionesEnviadas = null;
        if (tienePermisosSobreFuncionesEnviadas)
            renderTabFuncionesEnviadas = (<Nav.Link eventKey="FuncionesEnviadas" as={Link} to={{ pathname: "FuncionesEnviadas", search: search }}>Funciones enviadas</Nav.Link>)

        var renderTabNotificaciones = null;
        if (tienePermisosSobreNotificaciones)
            renderTabNotificaciones = (<Nav.Link eventKey="Notificaciones" as={Link} to={{ pathname: "Notificaciones", search: search }}>Notificaciones</Nav.Link>)

        var renderTabKilometraje = null;
        if (tienePermisosSobreKilometraje)
            renderTabKilometraje = (<Nav.Link eventKey="Kilometraje" as={Link} to={{ pathname: "Kilometraje", search: search }}>Kilometraje</Nav.Link>)

        let tabs = null;
        if (tabsActivos) {
            tabs = (
                <div className="reportesTabs">
                    <Nav variant="pills" className="nav-fill" activeKey={currentTab} onSelect={this.props.PestañaChange}>
                        <Nav.Item>{renderTabRecorrido}</Nav.Item>
                        <Nav.Item>{renderTabFuncionesEnviadas}</Nav.Item>
                        <Nav.Item>{renderTabNotificaciones}</Nav.Item>
                        <Nav.Item>{renderTabKilometraje}</Nav.Item>
                    </Nav>
                </div>
            );
        }

        return tabs;
    };
};

class OperacionesTab extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            visibleCaracteristicas: false
        }
    }

    AbrirEditarUnidad() {
        this.setState({ visibleCaracteristicas: true });
    }

    CerrarEditarUnidad() {
        this.setState({ visibleCaracteristicas: false });
        this.props.onActualizarInformacionUnidad();
    }

    renderVentanaCaracteristicas() {
        var idUnidad = this.props.idUnidadSeleccionada;

        let finalRender = (
            <ModalComponent
                title="VER CARACTERÍSTICAS"
                visible={this.state.visibleCaracteristicas}
                onHide={e => this.CerrarEditarUnidad()}>
                <CaracteristicasPage idUnidadSeleccionada={idUnidad} {...this.props} CerrarEditarUnidad={() => this.CerrarEditarUnidad()} />

            </ModalComponent>
        );

        return finalRender;
    }

    render() {
        const currentTab = this.props.location.pathname.replace("/", "");
        const search = this.props.location.search;

        // VERIFICAR QUE SI SE PUEDA PINTAR
        var tabsActivos = Functions.isInArray(currentTab, ["EnviarFuncion", "Geocercas", "Combustible"]);

        // PERMISOS
        var tipoUsuario = this.props.usuarioActual.Tipo;
        var noEsUsuarioTemporal = tipoUsuario >= 1 && tipoUsuario <= 3;
        var funcionesHabilitadas = this.props.usuarioActual.FuncionesHabilitadas;
        var tienePermisosSobreEnviarFuncion = noEsUsuarioTemporal || Functions.isInArray(8, funcionesHabilitadas);
        var tienePermisosSobreGeocercas = noEsUsuarioTemporal || Functions.isInArray(10, funcionesHabilitadas);
        var tienePermisosSobreRendimiento = noEsUsuarioTemporal || Functions.isInArray(11, funcionesHabilitadas);

        var renderEnviarFuncion = null;
        if (tienePermisosSobreEnviarFuncion)
            renderEnviarFuncion = (<Nav.Link eventKey="EnviarFuncion" as={Link} to={{ pathname: "EnviarFuncion", search: search }}>Enviar función</Nav.Link>)

        var renderGeocercas = null;
        if (tienePermisosSobreGeocercas)
            renderGeocercas = (<Nav.Link eventKey="Geocercas" as={Link} to={{ pathname: "Geocercas", search: search }}>Geocercas</Nav.Link>)

        var renderRendimiento = null;
        if (tienePermisosSobreRendimiento)
            renderRendimiento = (<Nav.Link eventKey="Combustible" as={Link} to={{ pathname: "Combustible", search: search }}>Combustible</Nav.Link>)

        var renderCaracteristicas = (<Nav.Link eventKey="Caracteristicas" onSelect={() => this.AbrirEditarUnidad()}>Características</Nav.Link>);

        let tabs = null;
        if (tabsActivos) {
            tabs = (
                <div className="operacionesTabs">
                    <Nav variant="pills" className="nav-fill" activeKey={currentTab} onSelect={this.props.PestañaChange}>
                        <Nav.Item>{renderEnviarFuncion}</Nav.Item>
                        <Nav.Item>{renderGeocercas}</Nav.Item>
                        {/* <Nav.Item>{renderRendimiento}</Nav.Item> */}
                        {/*<Nav.Item>{renderCaracteristicas}</Nav.Item>*/}
                    </Nav>

                    {this.renderVentanaCaracteristicas()}
                </div>
            );
        }

        return tabs;
    };
};

export default withRouter(ContentController);