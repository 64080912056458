import React from 'react';
import { Button } from 'semantic-ui-react';

class DownloadButton extends React.Component {
    render() {
        return (
            <a className="btn btn-info" target="_blank" href={this.props.url}><span className="fa fa-download"></span></a>
        )
    }
}

export default DownloadButton;