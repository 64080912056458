import React from 'react';
import FetchData from '../../Helpers/FetchData';
import Functions from '../../Helpers/Functions';
import { ScrollPanel } from 'primereact/scrollpanel';
import './EnviarFuncionPage.css';
import GlobeSvg from '../../../../assets/images/Globe.svg';
import UnlockSvg from '../../../../assets/images/Unlock.svg';
import KeyLeftSvg from '../../../../assets/images/KeyLeft.svg';
import KeyRightSvg from '../../../../assets/images/KeyRight.svg';
import BellSvg from '../../../../assets/images/Bell.svg';
import BellAlarmSvg from '../../../../assets/images/BellAlarm.svg';
import SvgComponent from '../../Helpers/SvgComponent';

class EnviarFuncionPage extends React.Component {
    constructor(props) {
        super(props)

        this.fetchDataComponent = React.createRef();
        this.GotoToTab = this.props.GotoToTab.bind(this);
    }

    createUrl() {
        const idUnidad = this.props.idUnidadSeleccionada;
        const finalUrl = process.env.REACT_APP_SERVICES_ENVIARFUNCION
            + "IdUnidad=" + idUnidad
        return finalUrl;
    }

    createUrlGuardar(idFuncion) {
        const idUnidad = this.props.idUnidadSeleccionada;
        const finalUrl = process.env.REACT_APP_SERVICES_ENVIARFUNCIONGUARDAR
            + "IdUnidad=" + idUnidad
            + "&IdFuncion=" + idFuncion
        return finalUrl;
    }

    enviarFuncionToUnidad_Click = async (idFuncion) => {
        if (window.confirm("¿Está seguro que desea enviar está función a la unidad?")) {
            var url = this.createUrlGuardar(idFuncion);

            // CONEXIÓN
            const response = await fetch(url, {
                credentials: 'include',
                cache: 'no-store'
            });
            if (!response.ok)
                alert('Error enviando la función a la unidad')
            const result = await response.text();

            if (result === "OK") {
                // const finalUrl = this.createUrl();
                // this.fetchDataComponent.current.actualizarData(finalUrl);
                this.GotoToTab("FuncionesEnviadas");
            } else {
                alert('Error procesando el envió de la funcion');
            }
        }
    };

    render() {
        const finalUrl = this.createUrl();

        let finalRender = (
            <FetchData url={finalUrl}>
                {data => this.renderTabla(data, this.enviarFuncionToUnidad_Click)}
            </FetchData>
        );

        return finalRender;
    }

    renderTabla(autorizaciones, onClick) {
        var renderFunciones = autorizaciones.map(function (autorizacion, index) {
            var funcionesQuemadas = [10, 200, 205, 206, 210, 211];
            var esFuncionQuemada = Functions.isInArray(autorizacion.Codigo, funcionesQuemadas);

            if (esFuncionQuemada) {
                var esPar = (index % 2);
                var ubicacion = esPar ? "float-right" : "float-left";
                var key = autorizacion.Codigo;

                return <Funcion key={key} ubicacion={ubicacion} data={autorizacion} onClick={onClick} />
            }
            else {
                return null;
            }
        });

        return (
            <div id="EnviarFuncionPage">
                <ScrollPanel style={{ height: '100%' }} className="customScroll">
                    <div className="Funciones">
                        {renderFunciones}
                    </div>
                </ScrollPanel>
            </div>
        );
    }
}

const Funcion = (props) => {
    var textos = {
        10: { Titulo: 'Reportar Ahora', Descripcion: 'Solicita a la unidad que envíe de inmediato su posición actual.', Icono: GlobeSvg, Color: 'border-naranja' },
        200: { Titulo: 'Abrir seguros', Descripcion: 'Solicita a la unidad que abra los seguros del carro.', Icono: UnlockSvg, Color: 'border-verde' },
        206: { Titulo: 'Encender Motor', Descripcion: 'Le indica a la unidad que encienda el motor del carro.', Icono: KeyRightSvg, Color: 'border-naranja' },
        205: { Titulo: 'Apagar Motor', Descripcion: 'Le indica a la unidad que apague el motor.', Icono: KeyLeftSvg, Color: 'border-rojo' },
        210: { Titulo: 'Encender Salida Auxiliar', Descripcion: 'Encendido de salida auxiliar conectada al claxon. Quedará encendida hasta que sea apagada.', Icono: BellAlarmSvg, Color: 'border-verde' },
        211: { Titulo: 'Apagar Salida Auxiliar', Descripcion: 'Apagado de salida auxiliar conectada al claxon. Quedará apagada hasta que sea encendida.', Icono: BellSvg, Color: 'border-rojo' },
    }

    var data = props.data;
    var estado = data.Estado;
    var funcionNoAutorizada = (estado === 0 || estado === 5);
    var funcionDeshabilitada = (estado !== 1);

    var finalRender = null;
    var renderFuncion = null;
    if (!funcionNoAutorizada) {
        var descripcionEstadoDeshabilitada = null;
        var claseFuncion = "Funcion";

        if (funcionDeshabilitada) {
            claseFuncion = "FuncionDeshabilitada";

            descripcionEstadoDeshabilitada = (
                <div className="DescripcionEstado">
                    {data.DescripcionEstado}
                </div>
            );
        }

        var color = "FuncionIcono rounded-circle border " + textos[data.Codigo].Color;

        renderFuncion = (
            <div className={claseFuncion} onClick={(e) => props.onClick(data.Codigo)}>
                <div className={color}>
                    <SvgComponent icono={textos[data.Codigo].Icono} />
                </div>
                <div className="FuncionInformacion">
                    <div className="FuncionTitulo">
                        {textos[data.Codigo].Titulo}
                    </div>
                    <div className="FuncionDescripcion">
                        {textos[data.Codigo].Descripcion}
                    </div>
                    {descripcionEstadoDeshabilitada}
                </div>
            </div>
        );

        finalRender = (
            <div className="FuncionContainer">
                {renderFuncion}
            </div >
        );
    }

    return finalRender;
}

export default EnviarFuncionPage;