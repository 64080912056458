import React from 'react';
import ProgressBar from "../Helpers/ProgressBar";
import { render } from 'react-dom';
import Async from "react-async";

// const getUrl = async ({ url }, { signal }) => {
//     const response = await fetch(url, { signal })

//     if (!response.ok)
//         throw new Error(response.statusText)

//     const data = await response.json();
//     return data;
// }

// const AsyncData = (props) => (
//     <Async promiseFn={getUrl} url={props.url}>
//         <Async.Pending>
//             <ProgressBar showProgressBar={true}></ProgressBar>
//         </Async.Pending>
//         <Async.Fulfilled>
//             {data => props.children(data)}
//         </Async.Fulfilled>
//         <Async.Rejected>
//             {(error, { finishedAt }) => `Something went wrong: ${error.message}`}
//         </Async.Rejected>
//     </Async>
// )


// class FetchData extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             internal: this.props.interval
//         };
//     }

//     render() {
//         var finalRender = (
//             <AsyncData url={this.props.url}>
//                 {data => this.props.children(data)}
//             </AsyncData>
//         );

//         return finalRender;
//     }
// }


class FetchData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            errorFetching: false,
            data: []
        };
    }

    actualizarData(url) {
        this.setState({ isFetching: true });
        this.fetchData(url);
    }

    componentDidMount() {
        this.fetchData(this.props.url);
    };

    fetchData(url) {
        fetch(url, {
            credentials: 'include',
            cache: 'no-store'
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("not ok");
                }

                return response.json();
            })
            .then((result) => {
                this.setState({ isFetching: false, errorFetching: false, data: result });
            })
            .catch((err) => {
                this.setState({ isFetching: false, errorFetching: true });
                console.info("error fetch. " + err);
            });
    };

    render() {
        let finalRender = null;

        if (this.state.isFetching) {
            finalRender = <ProgressBar showProgressBar={true}></ProgressBar>;
        } else if (this.state.errorFetching) {
            finalRender = <div>Error obteniendo los datos.</div>
        } else {
            finalRender = this.props.children(this.state.data);
        }

        return finalRender;
    }
}

export default FetchData;