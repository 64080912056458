import React from 'react';

const BotonComponent = (props) => {
    var htmlIcono;
    if (props.icon == "refresh") {
        htmlIcono = <span className="fa fa-refresh"></span>;
    }
    var finalRender = (
        <button className="btn btn-naranja" onClick={props.onClick}>
            {props.value}{htmlIcono}
        </button>
    );
    return finalRender
};

export default BotonComponent;