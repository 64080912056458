import React from 'react';
import { Dropdown } from 'semantic-ui-react';

class DropdownComponent extends React.Component {
    render() {
        var options = this.props.metadata.map(m => ({
            key: m.ID,
            text: m.Valor,
            value: m.ID,
        }));

        var text = "";
        for (var index = 0; index < this.props.metadata.length; ++index) {
            var m = this.props.metadata[index];
            if (m.ID == this.props.valor) {
                text = m.Valor;
            }
        }

        var finalRender = (
            <Dropdown selection options={options} value={this.props.valor} onChange={this.props.onChange} />
        );

        return finalRender;
    }
}

export default DropdownComponent;