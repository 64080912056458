import React from 'react';
import StreeView from '../../Helpers/StreetView'
import FetchData from '../../Helpers/FetchData';
import Functions from '../../Helpers/Functions';
import DateFormat from '../../Helpers/DateFormat';
import SinDatos from '../../Helpers/SinDatos';
import { ScrollPanel } from 'primereact/scrollpanel';
import './ResumenPage.css';
import SvgComponent from '../../Helpers/SvgComponent';
import CalendarSvg from '../../../../assets/images/Calendar.svg';
import SpeedSvg from '../../../../assets/images/Speed.svg';
import BellAlarmSvg from '../../../../assets/images/BellAlarm.svg';
import Form from 'react-bootstrap/Form';
import OkSvg from '../../../../assets/images/Ok.svg';

class ResumenPage extends React.Component {
    constructor(props) {
        super(props);

        this.fetchDataComponent = React.createRef();
        this.tiempoActualizacion = 60 * 1000;
    }

    createUrl() {
        const finalUrl = process.env.REACT_APP_SERVICES_RESUMEN + "IdUnidad=" + this.props.idUnidadSeleccionada;
        return finalUrl;
    }

    getData() {
        const finalUrl = this.createUrl();
        this.fetchDataComponent.current.actualizarData(finalUrl);
    }

    componentDidMount() {
        // ACTUALIZAR LA DATA CADA X TIEMPO
        this.interval = setInterval(() => this.getData(), this.tiempoActualizacion);
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        var finalUrl = this.createUrl();

        var finalRender = (
            <FetchData url={finalUrl} ref={this.fetchDataComponent}>
                {resumen => this.renderData(resumen)}
            </FetchData>
        );

        return finalRender;
    }

    renderData(resumen) {
        var ultimaPosicion = resumen.UltimaPosicion;
        var tieneUltimaPosicion = ultimaPosicion !== null;
        var finalRender = null;

        if (tieneUltimaPosicion) {
            this.props.PintarPosicion(resumen, resumen.UltimaPosicion);

            let seccionShipment = this.renderShipment(resumen);
            let seccionUltimaPosicion = this.renderSeccionUltimaPosicion(resumen);
            let informacionAdicional = this.renderSeccionInformacionAdicional(resumen);
            let seccionAlertas = this.renderSeccionAlertas(resumen);
            let seccionStreetView = this.renderStreetView(resumen);
            var posTopDataView = resumen.ShipmentVisible ? 40 : 0;

            finalRender = (
                <div id="ResumenPage">
                    <div className="DataView" style={{ top: posTopDataView }}>
                        <ScrollPanel style={{ height: '100%' }} className="customScroll">
                            <div className="StreetView">
                                {seccionStreetView}
                            </div>
                            <div className="ShipmentData">
                                {seccionShipment}
                            </div>
                            {seccionUltimaPosicion}
                            {informacionAdicional}
                            {seccionAlertas}
                        </ScrollPanel>
                    </div>
                </div>
            );
        } else {
            var mensaje = "La unidad no posee última posición.";
            finalRender = <div className="SinPosiciones"><SinDatos mensaje={mensaje} /></div>;
        }

        return finalRender;
    };

    renderShipment(resumen) {
        var finalRender = <ShipmentData resumen={resumen} />;
        return finalRender;
    }

    renderSeccionUltimaPosicion(resumen) {
        let seccionUltimaPosicion = null;
        var ultimaPosicion = resumen.UltimaPosicion;
        var tieneUltimaPosicion = ultimaPosicion !== null;

        if (tieneUltimaPosicion) {
            var textoVelocidad = ultimaPosicion.Velocidad + " km/h";
            var textoEstadoMotor = ultimaPosicion.EstadoMotor === 0 ? "Desconocido" : (ultimaPosicion.EstadoMotor === 1 ? "Encendido" : "Apagado")

            seccionUltimaPosicion = (
                <div className="Seccion border border-top-0 border-gris-claro">
                    <Titulo imagen={CalendarSvg} titulo="Última posición" />

                    <div className="Datos">
                        <Dato titulo="Fecha y hora" valor={<DateFormat fecha={ultimaPosicion.FechaHora} />} />
                        <Dato titulo="Ubicación" valor={ultimaPosicion.Ubicacion} />
                        <Dato titulo="Velocidad y dirección" valor={textoVelocidad} />
                        <Dato titulo="Coordenadas (lat-lon)" valor={ultimaPosicion.LatitudStr + ", " + ultimaPosicion.LongitudStr} />
                        <Dato titulo="Estado del motor" valor={textoEstadoMotor} />
                    </div>
                </div>
            );
        }
        return seccionUltimaPosicion;
    };

    renderSeccionInformacionAdicional(resumen) {
        let seccionUltimaPosicion = null;
        var ultimaPosicion = resumen.UltimaPosicion;
        var tieneUltimaPosicion = ultimaPosicion !== null;
        var tieneExtraFields = ultimaPosicion.ExtraFields !== null;

        if (tieneUltimaPosicion && tieneExtraFields) {
            seccionUltimaPosicion = (
                <div className="Seccion border border-top-0 border-gris-claro">
                    <Titulo imagen={SpeedSvg} titulo="Informacion adicional" />

                    <div className="Datos">
                        {ultimaPosicion.ExtraFields.map(
                            function (extraField, index) {
                                var valor = Functions.Capitalize(extraField.Valor).trim();
                                return <Dato key={index} titulo={extraField.Nombre} valor={valor} />
                            })
                        }
                    </div>
                </div>
            );
        }
        return seccionUltimaPosicion;
    };

    renderSeccionAlertas(resumen) {
        let seccionAlertas = null;
        var alertas = resumen.AlertasUlt24H;
        var tieneAlertasUlt24H = alertas !== null;

        if (tieneAlertasUlt24H) {
            seccionAlertas = (
                <div className="Seccion border border-top-0 border-gris-claro">
                    <Titulo imagen={BellAlarmSvg} titulo="Alertas (últimas 24 horas)" />

                    <div className="Datos">
                        {alertas.map(
                            function (alerta, index) {
                                return <Dato key={index} titulo={alerta.DescripcionAlerta} valor={<DateFormat fecha={alerta.FechaAlerta} />} />
                            })
                        }
                    </div>
                </div>
            );
        }
        return seccionAlertas;
    };

    renderStreetView(resumen) {
        let seccionStreetView = null;
        var ultimaPosicion = resumen.UltimaPosicion;
        var tieneUltimaPosicion = ultimaPosicion !== null;

        if (tieneUltimaPosicion) {
            seccionStreetView = (
                <StreeView fecha={ultimaPosicion.FechaHora} velocidad={ultimaPosicion.Velocidad} latitud={ultimaPosicion.Latitud} longitud={ultimaPosicion.Longitud} direccion={ultimaPosicion.Direccion}></StreeView>
            );
        }
        return seccionStreetView;
    }
}

const Titulo = (props) => {
    return (
        <div className="TituloSeccion bg-gris-claro">
            <span className="TituloSeccionIcono bg-naranja text-light">
                <SvgComponent icono={props.imagen} />
            </span>
            <span className="TituloSeccionTitulo">
                {props.titulo}
            </span>
        </div>
    );
};

const Dato = (props) => {
    return (
        <div className="Dato">
            <span className="DatoClave text-azul">{Functions.Capitalize(props.titulo).trim()}</span>
            <span className="DatoValor">{props.valor}</span>
        </div>
    );
};

class ShipmentData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shipmentData: { IdUnidad: this.props.resumen.IdUnidad, Shipment: this.props.resumen.Shipment }
        };
    }

    Shipment_Change = (event) => {
        this.setState({ shipmentData: { ...this.state.shipmentData, Shipment: event.target.value } });
    };

    Shipment_Update = async () => {
        var finalUrl = process.env.REACT_APP_SERVICES_UPDATESHIPMENT;

        // CONEXIÓN
        const response = await fetch(finalUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(this.state.shipmentData),
            credentials: 'include',
            cache: 'no-store'
        });

        // RESPONSE
        var responseMessage;
        if (!response.ok) {
            responseMessage = 'Error guardando la información';
        } else {
            var result = await response.text();

            if (result === "OK") {
                responseMessage = 'Actualización exitosa';
            } else {
                responseMessage = 'Error procesando. ' + result;
            }
        }
        alert(responseMessage);
    };

    render() {
        var finalRender = null;
        if (this.props.resumen.ShipmentVisible) {
            finalRender = (
                <div className="Dato">
                    <span className="DatoClave text-azul" style={{ lineHeight: "32px" }}>Número de viaje actual</span>
                    <span className="DatoValor" style={{ display: "inline-flex" }}>
                        <Form.Control value={this.state.shipmentData.Shipment} onChange={this.Shipment_Change} maxLength="20" />
                        <button className="btn btn-naranja" onClick={this.Shipment_Update}>
                            <SvgComponent icono={OkSvg} />
                        </button>
                    </span>
                </div>
            )
        }

        return finalRender;
    }
}

export default ResumenPage;