import React from 'react';
import ListView from './ListView/ListView'
import Form from 'react-bootstrap/Form'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button';
import Functions from '../../Helpers/Functions';
import SvgComponent from '../../Helpers/SvgComponent';
import Search from '../../../../assets/images/Search.svg';
import Sandwich from '../../../../assets/images/Sandwich.svg';

class SearchBar extends React.Component {
  state = {
    filter: "",
    tipoView: 2
  };

  TipoView_Change = (value) => {
    this.setState({ tipoView: value })
  }

  filter_Change = (event) => {
    this.setState({ filter: event.target.value });
  };

  render() {
    // FILTRO DE TEXTO
    const lowercasedFilter = Functions.removeDiacritics(this.state.filter.toLowerCase());
    let filteredData = this.props.unidades == null
      ? []
      : this.props.unidades.filter(item =>
        item.EstadoAdmin === 1
        && (
          item.StrUnidad.toLowerCase().includes(lowercasedFilter)
          || Functions.removeDiacritics(item.Descripcion.toLowerCase()).includes(lowercasedFilter)
          || item.UltimaPosicion != null && item.UltimaPosicion.VelocidadStr.toLowerCase().includes(lowercasedFilter)
          || item.UltimaPosicion != null && Functions.removeDiacritics(item.UltimaPosicion.Ubicacion).toLowerCase().includes(lowercasedFilter)
        )
      );
    var cantResultados = filteredData.length;
    var cantResultadosRender = cantResultados + " " + (cantResultados === 1 ? "unidad" : "unidades");

    // var variant1 = this.state.tipoView == 1 ? "detector-naranja_iconos" : "detector-gris";
    // var variant2 = this.state.tipoView == 2 ? "detector-naranja_iconos" : "detector-gris";

    var finalRender = (
      <div id="SearchBar">
        <div id="SearchBarHeader">
          <div className="SearchBarHeaderOuter bg-azul">
            <div id="SearchBarRow2">
              <InputGroup>
                <Form.Control placeholder="Filtrar unidad" value={this.state.filter} onChange={this.filter_Change} aria-describedby="filtrarPor" />
                <InputGroup.Append>
                  <Button variant="naranja" size="sm" className="BotonBusqueda">
                    <SvgComponent icono={Search} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>

            <div className="CantResultados text-gris-medio">
              {cantResultadosRender}
            </div>
          </div>
        </div>

        <ListView
          unidades={filteredData}
          tipoView={this.state.tipoView}

          MarcarUnidad={this.props.MarcarUnidad}
          DesmarcarUnidad={this.props.DesmarcarUnidad}
          MarcarUnidadClick={this.props.MarcarUnidadClick} />
      </div>
    );

    return finalRender;
  }
}

export default SearchBar;